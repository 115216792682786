import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";

// eslint-disable-next-line no-unused-vars
const UserContext = createContext<{ store: any; setStore: (user: any) => void } | undefined>(
  undefined
);

export function UserProvider({ children }: { children: ReactNode }) {
  const [store, setStore] = useState<any>(() => {
    const storedData = localStorage.getItem("data");
    return storedData ? JSON.parse(storedData) : null;
  });

  useEffect(() => {
    if (store) {
      localStorage.setItem("data", JSON.stringify(store));
    } else {
      localStorage.removeItem("data");
    }
  }, [store]);

  const contextValue = useMemo(() => ({ store, setStore }), [store, setStore]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export function useStore() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("Store must be used within a UserProvider");
  }
  return context;
}
